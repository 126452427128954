import axios from 'axios'
import utils from '../../utils/querybuilder'
import store from '../index'
import router from '../../router'

export default {
    state: {
        prospeccao: {},
        unidade: [],
        unidadeusuarios: [],
        prospeccoes: [],
        prospeccoespg: [],
        prospeccoesQualified: [],
        prospeccoesQualifiedpg: [],
        duplicado: true,
        edtProspec: false,
        prospeccoesDup: [],
        dialogContatosProspecData: false,
        dialogHistoricoProspecData: false,
        dialogOportunidadeProspecData: false,
        dialogCompetidorProspecData: false,
        savingProspec: false,
    },
    mutations: {
        UPDATE_PROSPECCAO(state, endereco) {
            // Update the state
            state.prospeccao = endereco;
        },
        setUnidade(state, payload) {
            state.unidade = payload
        },
        setUnidadeUsuarios(state, payload) {
            state.unidadeusuarios = payload
        },
        setDuplicado(state, payload) {
            state.duplicado = payload
        },
        setEdtUnidade(state, payload) {
            state.unidade = payload
        },
        setEdtUnidadeUsuarios(state, payload) {
            state.unidadeusuarios = payload
        },
        setEdtProspec(state, payload) {
            state.edtProspec = payload
        },
        setEdtQualifiedProspec(state, payload) {
            state.edtQualifiedProspec = payload
        },
        setEdtQualifiedProspecpg(state, payload) {
            state.edtQualifiedProspec = payload
        },
        setEndereco(state, payload) {
            state.prospeccao.endereco = payload
        },
        setProspData(state, payload) {
            state.prospeccao = { ...state.prospeccao, ...payload }
        },
        setDados(state, payload) {
            state.prospeccao.dados = payload
        },
        setContatos(state, payload) {
            state.prospeccao.contatos = payload
        },
        setSeguimentacao(state, payload) {
            state.prospeccao.seguimentacao = payload
        },
        setOrigem(state, payload) {
            state.prospeccao.origem = payload
        },
        setProspecao(state, payload) {
            state.prospeccao = payload
        },
        setProspecoes(state, payload) {
            state.prospeccoes = payload
        },
        setProspecoespg(state, payload) {
            state.prospeccoespg = payload
        },
        setProspecoesQualified(state, payload) {
            state.prospeccoesQualified = payload
        },
        setProspecoesQualifiedpg(state, payload) {
            state.prospeccoesQualifiedpg = payload
        },
        setProspecoesDup(state, payload) {
            state.prospeccoesDup = payload
        },
        setDialogContatosProspecData(state, payload) {
            state.dialogContatosProspecData = payload
        },
        setDialogHistoricoProspecData(state, payload) {
            state.dialogHistoricoProspecData = payload
        },
        setDialogOportunidadeProspecData(state, payload) {
            state.dialogOportunidadeProspecData = payload
        },
        setDialogCompetidorProspecData(state, payload) {
            state.dialogCompetidorProspecData = payload
        },
        setSavingProspec(state, payload) {
            state.savingProspec = payload
        },
    },
    actions: {
        updateProspeccao({ commit }, endereco) {
            commit('UPDATE_PROSPECCAO', endereco);
        },
        setUnidade(context, payload) {
            context.commit('setUnidade', payload)
        },
        setUnidadeUsuarios(context, payload) {
            context.commit('setUnidadeUsuarios', payload)
        },
        setEndereco(context, payload) {
            context.commit('setEndereco', payload)
        },
        setProspecao(context, payload) {
            context.commit('setProspecao', payload)
        },
        setProspecaopg(context, payload) {
            context.commit('setProspecaopg', payload)
        },
        setProspData(context, payload) {
            context.commit('setProspData', payload)
        },
        setEdtProspec(context, payload) {
            context.commit('setEdtProspec', payload)
        },
        setEdtQualifiedProspec(context, payload) {
            context.commit('setEdtQualifiedProspec', payload)
        },
        setDuplicado(context, payload) {
            context.commit('setDuplicado', payload)
        },
        setDados(context, payload) {
            context.commit('setDados', payload)
        },
        setContatos(context, payload) {
            context.commit('setContatos', payload)
        },
        setHistorico(context, payload) {
            context.commit('setHistorico', payload)
        },
        setSeguimentacao(context, payload) {
            context.commit('setSeguimentacao', payload)
        },
        selectProspection(context, payload) {
            console.log(context, payload);
        },
        setProspecoes(context, payload) {
            context.commit('setProspecoes', payload)
        },
        setProspecoespg(context, payload) {
            context.commit('setProspecoespg', payload)
        },
        setProspecoesQualified(context, payload) {
            context.commit('setProspecoesQualified', payload)
        },
        setProspecoesDup(context, payload) {
            context.commit('setProspecoesDup', payload)
        },
        setDialogContatosProspecData(context, payload) {
            context.commit('setDialogContatosProspecData', payload)
        },
        setDialogHistoricoProspecData(context, payload) {
            context.commit('setDialogHistoricoProspecData', payload)
        },
        setDialogOportunidadeProspecData(context, payload) {
            context.commit('setDialogOportunidadeProspecData', payload)
        },
        setDialogCompetidorProspecData(context, payload) {
            context.commit('setDialogCompetidorProspecData', payload)
        },
        setSavingProspec(context, payload) {
            context.commit('setSavingProspec', payload)
        },
        loadUnidade(context) {

            if (store.state.index.user.user.user_id === undefined) {
                // O usuário não está autenticado, então retornamos e evitamos a solicitação
                return;
            }
            axios(`/unidades/`).then((resp) => {
                if (resp.status < 300) {
                    // console.log(resp.data);
                    context.commit('setUnidade', resp.data);
                    // context.dispatch("setEdtUnidade", false);
                }
            })
                .then((resp) => {
                    if (resp) {
                        if (resp.status < 300) {
                            context.commit('setUnidade', resp.data);
                        }
                    }
                });
        },
        loadUnidadeUsuarios(context) {
            // console.log(`/unidadesusuarios/`);
            axios(`/unidadesusuarios/`).then((resp) => {
                if (resp.status < 300) {
                    // console.log('entrou');
                    // console.log(resp.data);
                    context.commit('setUnidadeUsuarios', resp.data);
                    // context.dispatch("setEdtUnidade", false);
                }
            });
        },

        loadProspeccoes(context, payload) {
            let filtro = context.getters.getFiltroPesquisa;
            filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            axios(`/clientes/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setProspecoes', resp.data);
                    // console.log(resp.data);
                    context.dispatch("setEdtProspec", false);
                }
            });
        },
        loadProspeccoespg(context, payload) {
            let filtro = context.getters.getFiltroPesquisa;
            context.commit("setLoading", true);
            filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            axios(`/clientes/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setProspecoespg', resp.data);
                    // console.log(resp.data);
                    context.dispatch("setEdtProspec", false);
                    context.commit("setLoading", false);
                }
            });
        },
        loadProspeccoesQualified(context) {
            let filtro = context.getters.getFiltroPesquisa;
            let queryString = utils.querybuilder(filtro);
            axios(`/qualificado/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setProspecoesQualified', resp.data);
                    context.dispatch("setEdtQualifiedProspec", false);
                }
            });
        },
        loadProspeccoesQualifiedpg(context, payload) {
            context.commit("setLoading", true)
            let filtro = context.getters.getFiltroPesquisa;
            filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            axios(`/qualificado/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setLoading', false)
                    context.commit('setProspecoesQualifiedpg', resp.data);
                    // console.log(resp.data);
                    // context.dispatch("setEdtQualifiedProspecpg", false);
                }
            });
        },
        findProspeccoes(context, payload) {
            let str = ''
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    str += `&${key}=${payload[key]}`
                }
            }
            str = str.replace('&', '?')
            // console.log(`/cep${str}`);
            // console.log(`duplicidade`, payload);
            axios(`/cep${str}`).then((resp) => {
                // console.log(resp.data);
                if (resp.status < 300) {
                    if (payload.dup) {
                        context.commit('setProspecoesDup', resp.data);
                    } else {
                        context.commit('setProspecoes', resp.data);
                    }
                }
            });
        },
        loadProspeccao(context, payload) {
            // let filtro = context.getters.getFiltroPesquisa;
            // filtro = { ...filtro, ...payload }
            // let queryString = utils.querybuilder(filtro);
            // console.log("loadProspeccao",`/clientes/${queryString}`);
            axios(`/clientes/${payload}/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setProspecao', resp.data);
                    context.dispatch("setEdtProspec", false);
                }
            });
        },
        loadProspeccaopg(context, payload) {
            let filtro = context.getters.getFiltroPesquisa;
            filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            // console.log("loadProspeccaopg",`/qualificado/${queryString}`);
            axios(`/clientes/?pg=1&${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setProspecaopg', resp.data);
                    context.dispatch("setEdtProspec", false);
                }
            });
        },
        async saveProspection(context, payload) {
            context.dispatch("setSavingProspec", true);
            const geocoder = new google.maps.Geocoder();
            let cliente = payload;
            if(cliente.complemento == ''){
                cliente.complemento = '.'
            };

            if(!cliente.ufId){
                context.dispatch("setSavingProspec", false);
                context.dispatch("showErrorSnack", "Necessário selecionar o estado");
                return
            }
            if(!cliente.cidadeId){
                context.dispatch("setSavingProspec", false);
                context.dispatch("showErrorSnack", "Necessário selecionar a cidade");
                return
            }
            
            const uf = context.getters.getEstadoById(payload.ufId);
            const cidade = context.getters.getCidadeNameById(payload.cidadeId);
            let endereco = `${cliente.numero} ${cliente.logradouro}, ${cliente.bairro}, ${cidade}, ${uf.estado}, 'Brasil'`;
            
            if(cliente.cepAntigo){
                cliente.lat = null
                cliente.lng = null
                cliente.cep = cliente.cepAntigo
            }
            if (cliente.cep && cliente.lat == null) {                
                geocoder.geocode({ address: endereco }, (results, status) => {
                    if (status === "OK") {
                        cliente.lat = results[0].geometry.location.lat();
                        cliente.lng = results[0].geometry.location.lng();
                        context.dispatch("salvarProspec", cliente);
                    } else {
                        context.dispatch("resetSnack");
                        context.dispatch("showErrorSnack", "Erro ao buscar as coordenadas deste endereço");
                        context.dispatch("salvarProspec", cliente);
                    }
                });
            }
            else {
                context.dispatch("salvarProspec", cliente);
            }
        },
        async salvarProspec(context, payload) {
            console.log('to aqui');
            
            if (payload.bairro == '' || payload.cep == '') {
                context.dispatch("resetSnack");
                context.dispatch("showErrorSnack", "Necessário buscar o CEP e preencher o Bairro");
                context.dispatch("setSavingProspec", false);
                return
            } else if(payload.numero == ''){
                context.dispatch("resetSnack");
                context.dispatch("showErrorSnack", "Necessário preencher o número");
                context.dispatch("setSavingProspec", false);
                return
            }
            const metodo = payload.id ? "patch" : "post";
            // console.log(payload);
            const finalUrl = payload.id ? `${payload.id}/` : "";

            // Envia a requisição para salvar a prospecção
            axios[metodo](`/clientes/${finalUrl}`, payload)
                .then((resp) => {
                    context.dispatch("setSavingProspec", false);
                    if (resp.status < 300) {
                        // Exibe uma mensagem de sucesso para o usuário
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Salvo com Sucesso");
                        context.dispatch("setEdtProspec", false);
                        const iniObs = metodo == "patch" ? "Editou" : "Criou"
                        let obj = {
                            observacao: `${iniObs} a prospecção no cliente ${payload.nome}`,
                            cliente: resp.data.id,
                            metodo: null,
                            parametro: ""
                        }
                        context.dispatch("saveHistorico", obj);
                        // Se a prospecção for salva com sucesso, envia a imagem (se houver)
                        if (payload.newimages) {
                            
                            
                            payload.newimages.forEach(element => {
                                if (!(element instanceof Blob)) return;
                                const formData = new FormData();
                                const clienteId = payload.id || resp.data.id;
                                // console.log( element instanceof Blob);
                                formData.append("imgsrc", element, element.name);
                                // console.log(element);
                                formData.append("cliente", clienteId);
                                
                                
                                axios.post("/clienteimagens/", formData, {
                                    headers: {
                                        'Content-Type': `multipart/form-data`,
                                    },
                                    timeout: 5000
                                }).then(() => {
                                    // console.log(resp);
                                    context.dispatch("resetSnack");
                                    context.dispatch("showSuccessSnack", "Cadastrado com Sucesso");
                                    context.dispatch("setEdtProspec", false);
                                }).catch(() => {
                                    // console.error(err);
                                    context.dispatch("resetSnack");
                                    context.dispatch("showErrorSnack", "Erro ao salvar a imagem");
                                });
                            });
                            
                        }

                        // Se a prospecção for salva, atualiza a etapa do cliente
                        let etapa = 1
                        if (
                            payload.nome &&
                            payload.cidadeId &&
                            payload.ufId &&
                            payload.logradouro &&
                            payload.numero &&
                            payload.unidadeId
                            // payload.vendedorId &&
                            ) {
                                etapa = 2
                            }
                            let etapacliente = {
                                cliente: resp.data.id,
                                etapa_id: etapa,
                            }
                            context.dispatch("saveEtapasClientes", etapacliente);
                            router.push({ name: 'home' });
                    }
                })
                .catch((err) => {
                    context.dispatch("setSavingProspec", false);
                    let errorMsg = "Erro ao salvar";
                    console.log(err);
                    // console.error(JSON.stringify(err, null, 2));  // Log the entire error object

                    if (err.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (err.response.data) {
                            if (err.response.data.detail) {
                                errorMsg = err.response.data.detail;
                            } else {
                                // If the response contains specific errors for each field, combine them into a single string
                                errorMsg = Object.values(err.response.data)
                                    .map(v => Array.isArray(v) ? v.join(', ') : v)
                                    .join('. ');
                            }
                        } else {
                            errorMsg = `Error ${err.response.status}: ${err.response.statusText}`;
                        }
                    } else if (err.request) {
                        // The request was made but no response was received
                        errorMsg = err.message;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMsg = err.message;
                    }

                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", errorMsg);
                })


        },
        deletProspection(context, payload) {
            axios.delete(`/clientes/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Prospecção Deletada com Sucesso");
                    context.dispatch("setEdtProspec", false);
                    // context.dispatch('loadProspeccoes')

                    let obj = {
                        observacao: `Deletou a prospecção no cliente ${payload.nome}`,
                        cliente: payload.id,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
        deletImage(context, payload) {
            axios.delete(`/clienteimagens/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Prospecção Deletada com Sucesso");
                    context.dispatch("setEdtProspec", false);
                    if (typeof payload.callback === 'function') {
                        payload.callback();
                    }
                    // context.dispatch('loadProspeccoes')
                    let obj = {
                        observacao: `Deletou uma imagem no cliente ${payload.nome}`,
                        cliente: payload.id,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
    },
    getters: {
        getUnidade(state) {
            return state.unidade
        },

        getUnidadeNameById: (state) => (id) => {
            if (!state.unidade) {
                return id
            }
            let obj = state.unidade.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.nome
            } else {
                return ""
            }
        },

        getSavingProspec(state) {
            return state.savingProspec
        },

        getUnidadeUsuarios(state) {
            return state.unidadeusuarios
        },

        getUnidadeSelect: (state) => {
            let gp = []
            state.unidade.forEach(element => {
                gp.push({ value: element.id, text: `(${element.id}) - ${element.nome}` })
            });
            return gp
        },

        getUnidadeUsuariosSelect: (state) => {
            let gp = [];
            // console.log('teste');
            // console.log(state.unidadeusuarios);
            if (state.unidadeusuarios.results && state.unidadeusuarios.results.length > 0) {
                state.unidadeusuarios.results.forEach((element) => {
                    gp.push({
                        value: element.unidade.id,
                        text: `(${element.unidade.id}) - ${element.unidade.nome}`,
                    });
                });
            }
            // console.log(gp);
            return gp;
        },
        getProspeccao(state) {
            return state.prospeccao
        },
        getDuplicado(state) {
            return state.duplicado
        },
        getProspeccoes(state) {
            return state.prospeccoes
        },
        getProspeccoespg(state) {
            return state.prospeccoespg
        },
        getProspecoesQualified(state) {
            return state.prospeccoesQualified
        },
        getProspecoesQualifiedPg(state) {
            return state.prospeccoesQualifiedpg
        },
        getEdtProspec(state) {
            return state.edtProspec
        },
        getProspeccoesDup(state) {
            return state.prospeccoesDup
        },
        getDialogContatosProspecData(state) {
            return state.dialogContatosProspecData
        },
        getDialogHistoricoProspecData(state) {
            return state.dialogHistoricoProspecData
        },
        getDialogOportunidadeProspecData(state) {
            return state.dialogOportunidadeProspecData
        },
        getDialogCompetidorProspecData(state) {
            return state.dialogCompetidorProspecData
        },
        getContactByProspIs: (state) => (id) => {
            if (!state.prospeccoes.length) {
                return []
            }
            let obj = state.prospeccoes.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.contatos
            } else {
                return []
            }
        },
        getProspeccaoNameById: (state) => (id) => {
            if (!state.prospeccoes) {
                return id
            }
            let obj = state.prospeccoes.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.nome
            } else {
                return ""
            }
        },
        getInputsToValidateProsp() {
            return [
                "atendenteId",
                "bairro",
                "cep",
                "cidadeId",
                "comoChegouId",
                "competidorId",
                "contatos",
                "cpfcnpj",
                "logradouro",
                "nome",
                "numero",
                "origemId",
                "segAlvo",
                "segmentoId",
                "ufId",
            ];
        },
    }
}