import { io } from "socket.io-client";
export default {
    state: {
        socket: null,
        statusWB: false
    },
    mutations: {
        connect(state, payload) {
            if (process.env.NODE_ENV === 'production') {
                state.socket = io("https://puc.stv.com.br", {path: "/wss/socket.io", auth: { token: payload.token, user: payload.user.user } });
            } else {                
                state.socket = io("https://puc.stv.com.br", {path: "/wss/socket.io", auth: { token: payload.token, user: payload.user.user } });
                // state.socket = io('https://empresamaissegura.stv.com.br', { auth: { token: payload } });
            }
        },
        disconnect(state) {
            state.socket.disconnect();
        },
        setStatus(state, payload) {
            state.statusWB = payload;
        },
    },
    actions: {
        connect(context, payload) {
            context.commit('connect', payload)
        },
        disconnect(context) {
            context.commit('disconnect')
        },
        setStatus(context, payload) {
            context.commit('setStatus', payload)
        },
    },
    getters: {
        getSocket(state) {
            return state.socket
        },
        getSocketStatus(state) {
            return state.statusWB
        },
    },
}