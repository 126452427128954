import { render, staticRenderFns } from "./Funil.vue?vue&type=template&id=2cebad63&scoped=true"
import script from "./Funil.vue?vue&type=script&lang=js"
export * from "./Funil.vue?vue&type=script&lang=js"
import style0 from "./Funil.vue?vue&type=style&index=0&id=2cebad63&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cebad63",
  null
  
)

export default component.exports