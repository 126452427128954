<template>
  <!-- <v-tabs fixed-tabs background-color="success" dark> -->
  <!-- <v-tab><v-icon>mdi-filter</v-icon>Funil de Vendas</v-tab> -->
  <!-- <v-divider></v-divider> -->

  <!-- <div v-show="showReports" @click.stop>
        <v-btn
          block
          @click="showReport = !showReport"
          @click.self="showReport = false"
          style="text-align: left; margin: 8px; padding: 8px"
          class="block"
        >
          <v-icon class="v-icon--small"> mdi-file-document </v-icon>
          Oportunidades
        </v-btn>

        <v-btn
          block
          @click="showReportQualified = !showReportQualified"
          @click.self="showReportQualified = false"
          style="text-align: left; margin: 8px; padding: 8px"
          class="block"
        >
          <v-icon class="v-icon--large"> mdi-file-document </v-icon>
          Qualificados
        </v-btn>
      </div> -->
  <!-- </v-tabs> -->
  <v-card class="elevation-0 transparent">
    <v-card-text>
      <v-row>
        <v-col auto>
          <Funil
            :agendamentos="getAgendaFunil.total_visitas"
            :diagnosticos="getDiagnosticoFunil.total_diagnosticos"
            :fechado="getNegociacaoFunil.total_negGanhas"
            :naoFechado="getNegociacaoFunil.total_negPerdidas"
            :propostas="getNegociacaoFunil.total_propostas"
            :negociacao="getNegociacaoAtivaFunil.total_negociacoes"
            :oportunidade="getProspecFunil.total_clientes"
            :qualificacao="getProspecFunil.total_qualificados"
            @selected="funilClicado"
          />
          <v-divider></v-divider>
        </v-col>
        <!-- {{ getShowMap}} -->
        <v-expand-transition>
          <v-col v-show="getShowMap" cols="12" md="6">
            <v-card
              class="elevation-0"
              :style="{
                backgroundColor: this.$vuetify.theme.dark
                  ? 'rgba(0, 0, 0, 0.6)'
                  : 'rgba(255, 255, 255, 0.6)',
              }"
            >
              <v-card-title>Mapeamento de Oportunidades</v-card-title>
              <v-card-text>
                <div id="map" style="height: 730px" class="fade-out"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Funil from "../components/Funil.vue";
import jsPDF from "jspdf";
import mapMixin from "../store/modules/maps.js";
import iconGreen from "../assets/icon-green.png";
import iconGray from "../assets/icon-gray.png";
import iconDarkBlue from "../assets/icon-darkblue.png";
import iconBlue from "../assets/icon-blue.png";
import iconOrange from "../assets/icon-orange.png";
import iconPurple from "../assets/icon-purple.png";
import iconlightGreen from "../assets/icon-lightgreen.png";

export default {
  name: "Home",
  mixins: [mapMixin],
  components: {
    Funil,
  },

  mounted() {
    // if (!this.googleMapsScriptLoaded) {
    //   this.initMap();
    // }
  },
  data: () => ({
    showReports: false,
    map: null,
    showMap: false,
    googleMapsScriptLoaded: false,
    markers: [],
    etapas: [],
    Qtdpropostas: null,
    Qtdnegociacao: null,
    negociacoesFechadasGanho: [],
    negociacoesFechadasPerdido: [],
    filteredSchedules: [],
    origens: [
      { id: 1, name: "Chamado" },
      { id: 2, name: "Prospecção" },
      { id: 3, name: "Pós-vendas" },
    ],
    search: "",
    perPage: 10,
    exportPDF: false,
    getEstados: [],
    getCidades: [],
    getVendedores: [],
    getResponsavel: [],
  }),
  methods: {
    formatOrigemId(id) {
      let origem = this.origens.find((origem) => origem.id === id);
      return origem ? origem.name : "Desconhecido";
    },
    findEtapaByClienteId(id) {
      let etapa = this.etapas.find((item) => item.cliente == id);
      return etapa ? etapa.etapa : "Desconhecido";
    },
    defineCor(etapa) {
      if (etapa == "Leads") {
        return iconGreen;
      } else if (etapa == "Qualificado") {
        return iconGray;
      } else if (etapa == "Visita") {
        return iconOrange;
      } else if (etapa == "Diagnostico") {
        return iconBlue;
      } else if (etapa == "Proposta") {
        return iconDarkBlue;
      } else if (etapa == "Negociação") {
        return iconPurple;
      } else if (etapa == "Finalizado Ganho") {
        return iconlightGreen;
      } else if (etapa == "Finalizado Perdido") {
        return iconlightGreen;
      }
    },
    async addMarkers() {
      this.markers.forEach((marker) => {
        // eslint-disable-next-line no-undef
        const markerObj = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          map: this.map,
          title: marker.title,
        });
        if (marker.etapa != "Desconhecido") {
          markerObj.setIcon({
            url: this.defineCor(marker.etapa),
            scaledSize: new google.maps.Size(40, 40),
          });
        }
        // Crie o conteúdo do InfoWindow com os dados do cliente
        const contentString = `
          <div style="color:black;">
            <h3 class="tituloCliente">${marker.title}</h3>
            <p>CPF/CNPJ: ${marker.cpfcnpj ? marker.cpfcnpj : ""}</p>
            <p>Endereço: ${marker.endereco}, ${marker.numero}</p>
            <p>CEP: ${marker.cep}</p>
            <!-- Adicione mais informações conforme necessário -->
          </div>
        `;

        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
        });

        markerObj.addListener("click", () => {
          infoWindow.open(this.map, markerObj);
        });
      });
    },
    countAtivosInPayload() {
      // console.log("aham", this.getNegociacoes);
      if (this.getNegociacoes.length === 0) {
        this.activePayloadCount = 0;
        this.Qtdpropostas = 0;
      } else {
        if (Array.isArray(this.getNegociacoes)) {
          let activePayloadCount = 0;
          let countedClients = {};

          this.getNegociacoes.forEach((negociacao) => {
            // Se o cliente já foi contado, vá para a próxima negociação.
            // if (countedClients[negociacao.cliente.id]) {
            //   return;
            // }

            let payloadAtivo = negociacao.payload.find(
              (payload) => payload.ativo === true
            );

            // Se o cliente tem um payload ativo, aumente o contador e marque o cliente como contado.
            if (payloadAtivo) {
              activePayloadCount += 1;
              countedClients[negociacao.cliente.id] = true;
            }
          });

          this.activePayloadCount = activePayloadCount;
          this.Qtdpropostas = activePayloadCount;
        }
      }
    },
    // countAtivosInPayload() {
    //   if (Array.isArray(this.getNegociacoes)) {
    //     let activePayloadCount = 0;
    //     let countedPayloadsForClient = {};

    //     this.getNegociacoes.forEach((negociacao) => {
    //       if (
    //         negociacao.cliente.negociacaoId &&
    //         !countedPayloadsForClient[negociacao.cliente.negociacaoId]
    //       ) {
    //         let payloadAtivo = negociacao.payload.find(
    //           (payload) => payload.ativo === true
    //         );
    //         if (payloadAtivo) {
    //           activePayloadCount += 1;
    //           countedPayloadsForClient[negociacao.cliente.negociacaoId] = true;
    //         }
    //       }
    //     });

    //     this.activePayloadCount = activePayloadCount;
    //     this.Qtdpropostas = activePayloadCount;
    //   }
    // },
    getNegociacoesAtivas() {
      if (Array.isArray(this.getNegociacoes)) {
        let negociacoesAtivas = [];
        let contagemClientes = {};

        this.getNegociacoes.forEach((negociacao) => {
          // Verificar se negociacaoId é definido e não foi contado ainda
          if (
            negociacao.cliente.negociacaoId &&
            !contagemClientes[negociacao.cliente.negociacaoId]
          ) {
            negociacoesAtivas.push(negociacao);
            contagemClientes[negociacao.cliente.negociacaoId] = true;
          }
        });
        this.Qtdnegociacao = negociacoesAtivas.length;
      }
    },

    getNegociacoesFechadasGanho() {
      if (Array.isArray(this.getNegociacoesFG)) {
        let negociacoesFechadasGanho = [];
        let contagemClientes = {};

        this.getNegociacoesFG.forEach((negociacao) => {
          // Verificar se negociacaoId é definido e não foi contado ainda
          if (
            negociacao.cliente.negociacaoId &&
            !contagemClientes[negociacao.cliente.negociacaoId]
          ) {
            negociacoesFechadasGanho.push(negociacao);
            contagemClientes[negociacao.cliente.negociacaoId] = true;
          }
        });
        // this.Qtdnegociacao = negociacoesFechadasGanho.length;
        // console.log("filtrado ganhos",negociacoesFechadasGanho);
        this.negociacoesFechadasGanho = negociacoesFechadasGanho;
      }
    },

    filter_one_per_client() {
      let filteredSchedules = [];
      let countedClients = {};

      this.getAgendamentos.forEach((agendamento) => {
        // Verificar se 'cliente' é definido e 'id' do cliente não foi contado ainda
        if (agendamento.cliente && !countedClients[agendamento.cliente.id]) {
          filteredSchedules.push(agendamento);
          countedClients[agendamento.cliente.id] = true;
        }
      });

      this.filteredSchedules = filteredSchedules;
    },

    getNegociacoesFechadasPerdido() {
      if (Array.isArray(this.getNegociacoesFP)) {
        let negociacoesFechadasPerdido = [];
        let contagemClientes = {};

        this.getNegociacoesFP.forEach((negociacao) => {
          // Verificar se negociacaoId é definido e não foi contado ainda
          if (
            negociacao.cliente.negociacaoId &&
            !contagemClientes[negociacao.cliente.negociacaoId]
          ) {
            negociacoesFechadasPerdido.push(negociacao);
            contagemClientes[negociacao.cliente.negociacaoId] = true;
          }
        });
        // this.Qtdnegociacao = negociacoesFechadasPerdido.length;
        // console.log("filtrado perdidos",negociacoesFechadasPerdido);
        this.negociacoesFechadasPerdido = negociacoesFechadasPerdido;
      }
    },

    loadCidades(id) {
      let uf = this.getEstadosSelect.find((e) => e.value === id);
      this.$store.dispatch("loadCidades", uf.uf);
      // console.log(uf);
    },
    limparFiltro() {
      this.$store.dispatch("setFiltroPesquisa", {});
    },
    funilClicado(data) {
      if (data == "oportunidade") {
        this.$router.push({ path: "/listarProspec" });
      }
      if (data == "propostas") {
        this.$router.push({ path: "/lstPropostas" });
      }
      if (data == "qualificacao") {
        this.$router.push({ path: "/listarProspecQuali" });
      }
      if (data == "agendamentos") {
        this.$router.push({ path: "/agendamento" });
      }
      if (data == "diagnosticos") {
        this.$router.push({ path: "/listarProspecDiag" });
      }
      if (data == "negociacao") {
        this.$router.push({ path: "/lstNegociacoes" });
      }
      if (data == "fechamento") {
        this.$router.push({ path: "/negociacoesFinalizadas" });
      }
      if (data == "fechado") {
        this.$router.push({ path: "/fechado" });
      }
      if (data == "naoFechado") {
        this.$router.push({ path: "/naoFechado" });
      }
    },
    exportPdf() {
      const columns = this.headers.map((header) => header.value);
      const rows = this.getProspeccoes.map((prospeccao) =>
        columns.map((col) => prospeccao[col])
      );

      const doc = new jsPDF("p", "pt");
      doc.autoTable({
        head: [columns],
        body: rows,
      });

      const blob = doc.output("blob");
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "prospeccoes.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    },
    printTable() {
      window.print();
    },
    async getMarkers() {
      this.$http.get("/etapacliente").then((resp) => {
        if (resp.status < 300) {
          this.etapas = resp.data;
          this.markers = this.getMarcadores.map((cliente) => {
            const lat = parseFloat(cliente.lat);
            const lng = parseFloat(cliente.lng);
            const marker = {
              lat: lat,
              lng: lng,
              title: cliente.nome,
              cpfcnpj: cliente.cpfcnpj,
              endereco: cliente.logradouro,
              numero: cliente.numero,
              cep: cliente.cep,
              clienteId: cliente.id,
              etapa: this.findEtapaByClienteId(cliente.id),
            };
            return marker;
          });
        }
      });
    },
    setNotification(titulo, mensagem, query = "") {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setNotification(titulo, mensagem, query);
          }
        });
      } else {
        const notification = new Notification(titulo, {
          body: mensagem,
          icon: "https://puc.stv.com.br/img/icons/favicon-32x32.png",
        });

        // Ações adicionais podem ser adicionadas
        notification.onclick = function () {
          const url = window.location.origin + query;          
          window.open(url);
          // window.open("https://puc.stv.com.br" + query);
        };

        notification.onclose = function () {
          console.log("Notificação fechada.");
        };
      }
    },
    async connectSocket() {
      if(this.$store.getters.getSocket == null){
        console.log('Conectando ao socket');
        
        this.$store.dispatch("connect", {token: this.$store.getters.getToken, user: this.$store.getters.getUser});
        const skt = this.$store.getters.getSocket;
        
        skt.on("connect", () => {
          console.log("Conectado ao socket");
        });

        skt.on("disconnect", () => {
          console.log("Desconectado do socket");

        });

        skt.on("new-cliente", (data) => {
          const titulo = "Novo cliente - " + data.nome; 
          const mensagem = "Um novo cliente foi cadastrado";
          const query = "/?email=" + data.id;
          this.setNotification(titulo, mensagem, query);
        });
      }
    },
  },

  watch: {
    getProspeccao(value) {
      if (this.$route.params.id) {
        this.endereco = value;
        this.loadCidades(this.endereco.ufId);
      }
    },
    getNegociacoes() {
      // if (this.getNegociacoes.length > 0) {
      // Negociacoes data is available, run the processing function(s)
      this.countAtivosInPayload();
      this.getNegociacoesAtivas();
      // this.getNegociacoesFechadasPerdido();
      // }
    },
    getAgendamentos() {
      // if (this.getAgendamentos.length > 0) {
      // Agendamentos data is available, run the processing function(s)
      this.filter_one_per_client();
      // }
    },
    getNegociacoesFG() {
      // if (this.getNegociacoesFG.length > 0) {
      // Negociacoes data is available, run the processing function(s)
      this.getNegociacoesFechadasGanho();
      // }
    },
    getNegociacoesFP() {
      // if (this.getNegociacoesFP.length > 0) {
      this.getNegociacoesFechadasPerdido();
      // }
    },
    getShowMap() {
      if (this.getShowMap) {
        this.showMap = this.getShowMap;
        if (this.getShowMap == true) {
          this.$store.dispatch("loadMarcadores");
        }
      }
    },
    getMarcadores: {
      handler() {
        this.getMarkers();
      },
      deep: true,
    },
    markers: {
      handler() {
        if (this.markers.length > 0) {
          this.addMarkers();
        }
      },
      deep: true,
    },
    getProspecFunil() {
      if (this.showMap == true) {
        this.$store.dispatch("loadMarcadores");
      }
    },
  },
  computed: {
    ...mapGetters([
      "getDuplicado",
      "getProspeccoes",
      "getProspecoesQualified",
      "getAgendamentos",
      "getDiagnosticos",
      "getNegociacoes",
      "getNegociacoesFG",
      "getNegociacoesFP",
      "getShowMap",
      "getMarcadores",

      // getters do funil:
      "getProspecFunil",
      "getAgendaFunil",
      "getDiagnosticoFunil",
      "getNegociacaoFunil",
      "getNegociacaoAtivaFunil",
    ]),
  },

  created() {
    this.$store.dispatch("loadSegmentacao");
    this.$store.dispatch("loadEstados");
    this.$store.dispatch("loadCidades");
    this.$store.dispatch("loadUsuarios");
    this.$store.dispatch("loadComoChegou");

    this.getNegociacoesAtivas();
    this.countAtivosInPayload();
    this.getNegociacoesFechadasGanho();
    this.getNegociacoesFechadasPerdido();
    this.filter_one_per_client();
  },
  mounted() {
    // dispatch's do funil:
    if (this.$store.getters.getClienteFiltro == null) {
      this.$store.dispatch("loadProspecfunil");
      this.$store.dispatch("loadDiagnosticoFunil");
      this.$store.dispatch("loadNegociacaoFunil");
      this.$store.dispatch("loadNegociacaoativaFunil");
      setTimeout(() => {
        this.$store.dispatch("loadAgendaFunil");
      }, "5000");
    }
    this.connectSocket();
  },
};
</script>
<style scoped>
.mao {
  cursor: pointer;
}
.vermelho {
  fill: brown;
}
.oitenta {
  max-width: 80%;
}

.fade-out {
  transition: opacity 0.5s;
  opacity: 1;
}

.fade-out.leave-active {
  opacity: 0;
}
</style>
