<template>
  <div>
    <v-system-bar v-if="$route.path != '/login'" app class="sys-bar"
      ><div
        @mouseenter="verificaTamanhoTexto"
        @mouseleave="removeTextoRolante"
        class="text-sys-bar"
      >
        <span id="filtrar-sp">Filtrando por:</span>
        <span v-html="textoFiltros"></span></div
    ></v-system-bar>
    <v-app-bar v-if="$route.path != '/login'" app class="fundostv d-print-none">
      <v-app-bar-nav-icon>
        <v-icon @click="$router.push({ name: 'home' })"
          >mdi-home-outline</v-icon
        >
      </v-app-bar-nav-icon>
      <v-app-bar-title>PUC</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="showFiltro" text style="text-align: left">
        <v-icon>mdi-filter</v-icon>
        {{ $vuetify.breakpoint.width < 600 ? "" : "Filtro" }}
      </v-btn>
      <v-btn
        class="hidden-print-only btn-block"
        @click="abrirMapa"
        text
        style="text-align: left"
      >
        <v-icon class="v-icon--large"> mdi-map </v-icon>
        {{ $vuetify.breakpoint.width < 600 ? "" : "Mapa" }}
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" style="text-align: left" text>
            <v-icon class="v-icon--large"> mdi-dots-vertical</v-icon>
            {{ $vuetify.breakpoint.width < 600 ? "" : "Relatórios" }}
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            @click="$router.push({ name: 'relatoriooportunidades' })"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Oportunidades</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push({ name: 'relatorioqualificados' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Qualificados</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatorioagenda' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Agenda</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatorioEstrelas' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Estrelas</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatoriomaster' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" icon v-bind="attrs" v-on="on">
            <v-avatar size="50px">
              <v-img :src="djangoUrl + getAvatar" v-if="getAvatar"></v-img>
              <v-icon v-else large>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
          <span class="mx-5" v-if="getLarguraTela > 600">
            {{ perfil.first_name }} {{ perfil.last_name }}</span
          >
        </template>
        <v-list>
          <v-list-item link @click="$router.push({ name: 'perfil' })">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            Perfil
          </v-list-item>
          <v-list-item link @click="changeTheme">
            <v-list-item-icon>
              <v-icon>mdi-palette</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Tema: {{ this.$vuetify.theme.dark ? "Escuro" : "Claro" }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logoutnow">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <Filtro ref="DialogFiltro" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Filtro from "../filtro.vue";
import Vue from "vue";

export default {
  name: "AppBar",
  components: {
    Filtro,
  },
  data() {
    return {
      perfil: {},
      djangoUrl: process.env.VUE_APP_DJANGO_URL,
      textoFiltros: "",
    };
  },
  methods: {
    abrirMapa() {
      this.showMap = !this.showMap;
      this.$store.dispatch("updateShowMap", this.showMap);
    },
    showFiltro() {
      this.$refs.DialogFiltro.openDialog();
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    async getPerfil() {
      try {
        if (this.$store.state.index.user.user.user_id === undefined) {
          return;
        }
        this.user = await this.$store.state.index.user.user.user_id;
        // console.log("user", this.user);
        let response = await this.$http.get("user/detail/" + this.user + "/");
        this.perfil = response.data;
        
        sessionStorage.setItem("username_puc", this.perfil.first_name + " " + this.perfil.last_name);

        
        Vue.use(require("vue-moment"));
        this.perfil.date_joined = this.$moment(this.perfil.date_joined).format(
          "DD/MM/YYYY HH:mm:ss"
        );
        this.perfil.last_login = this.$moment(this.perfil.last_login).format(
          "DD/MM/YYYY HH:mm:ss"
        );
        if (this.perfil.first_login) {
          this.$router.push({ name: "perfil" }).catch(() => {});
        }
      } catch (error) {
        // console.log("user", "erro 4");
        //redirect
        this.$router.push({ name: "login" }).catch(() => {});
        console.log(error);
      }
    },
    logoutnow() {
      // this.$store.dispatch("logout");
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ path: "/login" });
    },
    verificaTamanhoTexto() {
      let el = document.querySelector(".text-sys-bar");
      let sysBar = document.querySelector(".sys-bar");
      el.classList.remove("texto-rolante");

      if (el.clientWidth > sysBar.offsetWidth) {
        el.classList.add("texto-rolante");
      }
    },
    removeTextoRolante() {
      let el = document.querySelector(".text-sys-bar");
      el.classList.remove("texto-rolante");
    },
    separaFiltros(textoFiltros) {
      let filtros = textoFiltros.split(" | ");
      let texto = "";
      filtros.forEach((filtro, idx) => {
        if (filtro != "") {
          texto += `<span style="${this.getStyle(
            idx
          )} padding: 2px 5px; border-radius: 5px; font-weight: bold; margin: 0 3px;">${filtro}</span>`;
        }
      });
      return texto;
    },
    getClass(n) {
      return n % 2 == 0 ? "color-1" : "color-2";
    },
    getStyle(n) {
      return n % 2 == 0
        ? "background-color: #6dc95f;"
        : "background-color: #eeea1b;";
    },
  },
  computed: {
    ...mapGetters(["getLarguraTela", "getAvatar", "getTextoFiltro"]),
  },
  watch: {
    getTextoFiltro(val) {
      this.textoFiltros = this.separaFiltros(val);
    },
  },
  mounted() {
    this.getPerfil();
  },
  created() {},
  destroyed() {},
};
</script>

<style scoped>
.sys-bar {
  display: block;
}

.text-sys-bar {
  white-space: nowrap;
  position: absolute;
}

.texto-rolante {
  animation: marquee 60s linear infinite;
}

#filtrar-sp {
  font-weight: bold;
  background-color: #43813a;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 3px;
}

.color-1 {
  background-color: #15dbd1;
}

.color-2 {
  background-color: #eeea1b;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>